import React from "react";
import HeroSection from "../../HeroSection";
import Mission from "./Mission";

const Home = () => {
  return (
    <>
      <HeroSection />
      <Mission />
    </>
  );
};

export default Home;
