import React from 'react';
import '../workshops-components/Workshop.css';

const Events = () => {
  return (
    <>
        <div className="events-hero">
            <h1>Coming Soon...</h1>
        </div>
    </>
  ) 
}

export default Events