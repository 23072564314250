import researchImg1 from "./images/Image Container.svg";
import researchImg2 from "./images/Image Container (1).svg";
import researchImg3 from "./images/Image Container (2).svg";
import researchImg4 from "./images/Image Container (3).svg";

export const researchData = [
    {
        id: 1,
        image: researchImg1,
        text: "STEM girls are breaking barriers: They are challenging stereotypes and proving that gender is not a barrier to success in STEM fields.",
        backgroundColor: "#FFEAEA",
    },
    {
        id: 2,
        image: researchImg2,
        text: "They are role models: STEM girls inspire others with their passion, dedication, and resilience in pursuing their STEM aspirations.",
        backgroundColor: "#E6EDFF",
    },
    {
        id: 3,
        image: researchImg3,
        text: "They are lifelong learners: STEM girls are curious and eager to learn, constantly seeking new knowledge and skills to further their understanding of the world.",
        backgroundColor: "#DEFFDE",
    },
    {
        id: 4,
        image: researchImg4,
        text: "They are shaping the future: STEM girls are the leaders and innovators of tomorrow, shaping the future of technology, science, engineering, and mathematics with their ideas, passion, and expertise.",
        backgroundColor: "#FDFFE6",
    },
];
